import React from 'react';
import logo from '../../_images/logo.png'

const Logo = () => {
    
    return (
        <div>
            <h1>
                <img src={logo} className='img-fluid' alt='logo'/>
                Soleil du Loir
            </h1>
            
        </div>
    );
};

export default Logo;