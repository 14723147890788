import React from 'react';
import { Link } from 'react-router-dom';

import './header.css'
import NavBar from './NavBar';
import Logo from '../logo/Logo';

const Header = () => {
    return (
        <header className='header'>
            <Logo/>
            <NavBar/>
        </header>
    );
};

export default Header;
