import React from "react";
import {NavLink} from "react-router-dom";
import { accountService } from "../../_services/account.service";

const NavBar = (props) => (
    <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="true" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01">
                <ul className="navbar-nav mr-auto" style={{paddingLeft:'50 px'}}>
                    <li className="nav-item">
                        <NavLink to="/" exact className="nav-link">Accueil</NavLink>
                    </li>
                    {
                        accountService.isLogged() &&
                        <>
                            <li className="nav-item">
                                 <NavLink to="/adherents" exact className="nav-link">Adhérents</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/documentation" exact className="nav-link">Documentation</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/mailing" exact className="nav-link">Mailing</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/logout" exact className="nav-link">Deconnection</NavLink>
                            </li>
                        </>
                    }
                    {
                        !accountService.isLogged() &&
                        <>
                            <li className="nav-item">
                                <NavLink to="/contact" exact className="nav-link">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/adhesion" exact className="nav-link">Adhésion</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/login" exact className="nav-link">Connection</NavLink>
                            </li>                        
                        </>
                    }
                </ul>
            </div>
        </nav>
    </>
);

export default NavBar;