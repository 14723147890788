import React from 'react';
import { withFormik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import './login.css'
import { accountService } from '../../../_services/account.service';

const Login = (props) => {
  const erreur= !(props.touched.email && props.touched.password && !props.errors.email && !props.errors.password);
    return (  
        <>
            <form data-form-type="other">
                <fieldset>
                <legend>Veuillez vous connecter</legend>
                <div className='mb-3'>
                  <span className='text-danger' id='message' name='message'></span>
                </div>
                    <div className="mb-3">
                        <label for="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="Votre email"aria-describedby="emailHelp"
                            name='email'
                            onChange={props.handleChange}
                            value={props.values.email}
                            onBlur={props.handleBlur}
                        />
                        {props.touched.email && props.errors.email && <span className='text-danger'>{props.errors.email}</span>}
                    </div>
                    <div className="form-group">
                        <label for="password" className="form-label mt-4">Password</label>
                        <input type="password" className="form-control" id="password" placeholder="votre mot de passe" autocomplete="off" data-dashlane-rid="a298491225ccf496" data-kwimpalastatus="alive" data-kwimpalaid="1691182305139-2" data-form-type="password"
                            name='password'
                            onChange={props.handleChange}
                            value={props.values.password}
                            onBlur={props.handleBlur}
                        />
                        {props.touched.password && props.errors.password && <span className='text-danger'>{props.errors.password}</span>}
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" onClick={props.handleSubmit}>Envoyez</button>
                    </div>
                    <div className="col">
                      <a href="#!">Mot de passe oublié</a>
                    </div>
                  </fieldset>
            </form>            
        </>
    );
};
{/* <form>
  <!-- Email input -->
  <div className="form-outline mb-4">
    <input type="email" id="form2Example1" className="form-control" />
    <label className="form-label" for="form2Example1">Email address</label>
  </div>

  <!-- Password input -->
  <div className="form-outline mb-4">
    <input type="password" id="form2Example2" className="form-control" />
    <label className="form-label" for="form2Example2">Password</label>
  </div>

  <!-- 2 column grid layout for inline styling -->
  <div className="row mb-4">
    <div className="col d-flex justify-content-center">
      <!-- Checkbox -->
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" id="form2Example31" checked />
        <label className="form-check-label" for="form2Example31"> Remember me </label>
      </div>
    </div>

    <div className="col">
      <!-- Simple link -->
      <a href="#!">Forgot password?</a>
    </div>
  </div>

  <!-- Submit button -->
  <button type="button" className="btn btn-primary btn-block mb-4">Sign in</button>

  <!-- Register buttons -->
  <div className="text-center">
    <p>Not a member? <a href="#!">Register</a></p>
    <p>or sign up with:</p>
    <button type="button" className="btn btn-link btn-floating mx-1">
      <i className="fab fa-facebook-f"></i>
    </button>

    <button type="button" className="btn btn-link btn-floating mx-1">
      <i className="fab fa-google"></i>
    </button>

    <button type="button" className="btn btn-link btn-floating mx-1">
      <i className="fab fa-twitter"></i>
    </button>

    <button type="button" className="btn btn-link btn-floating mx-1">
      <i className="fab fa-github"></i>
    </button>
  </div>
</form> */}

export default  withFormik({
    mapPropsToErrors : () =>({
        email:'',
        password:'',
    }),
    validationSchema : Yup.object().shape({
        email: Yup.string()
        .email('Format email invalide')
        .required("Email obligatoire"),
        password: Yup.string()
        .required('Mot de passe est obligatoire'),
    }),
    handleSubmit: (values,{props}) =>{
        const data = {
          domaine:'front',
          action:'login',
          email : values.email,
          password: values.password
        }
        let url = accountService.getURL();
        axios.post(url,
          data,{
          headers:{
            'Content-Type':'multipart/form-data',
          }}
        )
        .then(reponse =>{
          if(reponse.data.OK){
            accountService.saveToken(reponse.data.token);
            window.location.href= '/';
          }
          else{
            console.log(values);
            let span = document.getElementById("message");
            let txt = document.createTextNode("Identifiants invalides");
            span.appendChild(txt);
            //document.getElementById('message').textContent = 'Identifiants invalides'
          }
        })
    }

})(Login);