import React from 'react';
import axios from 'axios';
import { accountService } from '../../_services/account.service';

const Adherents = () => {

    let data={
        'domaine':'front',
        'action':'listeAdherents'
    }
    let url = accountService.getURL();
    axios.post(url,
      data,{
      headers:{
        'Content-Type':'multipart/form-data',
        'Authorization': 'Bearer ' + accountService.getToken()
      }}
    )
    .then(reponse =>{
        !reponse.data.OK && console.log(reponse.data.message);
        reponse.data.OK && console.log(reponse.data.listeAdherents);
    })

    return (
        <div>
            page adhérents
        </div>
    );
};

export default Adherents;