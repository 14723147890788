import React from 'react';

import './acceuil.css'
import grues from './grue_chantier.jpg'

const Acceuil = () => {
    return (
        <div className='container'>
            <h1 className='titre'>
            Site en construction
            </h1>
        </div>
    );
};

export default Acceuil;