
let saveToken=(token)=>{
    localStorage.setItem('token',token)
}

let logOut=()=>{
    localStorage.removeItem('token')
}

let isLogged=()=>{
    let token=localStorage.getItem('token')
    return !!token
}

let getToken=()=>{
    return localStorage.getItem('token')
}

let getURL=()=>{
    //return 'http://localhost/siteSDL_dev/serveurSDL/index.php';
    return 'https://www.try.florifounelle.fr/serveurSDL/index.php';
}

export const accountService={
    saveToken,logOut,isLogged,getToken,getURL
}