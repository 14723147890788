import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Layout from './pages/public/Layout';
import Accueil from './pages/public/acceuil/Acceuil';
import Contact from './pages/public/Contact';
import Adhesion from './pages/public/Adhesion';
import Login from './pages/public/login/Login';
import Adherents from './pages/prive/Adherents';
import Documentation from './pages/prive/Documentation';
import Mailing from './pages/prive/Mailing';
import Error from './components/errors/Error';
import Logout from './pages/public/login/Logout';


function App() {
  
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index='/'  element={<Accueil />} />
          <Route path='/contact' element={<Contact />}/>
          <Route path='/adhesion' element={<Adhesion />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/adherents' element={<Adherents />} />
          <Route path='/documentation' element={<Documentation />} />
          <Route path='/mailing' element={<Mailing />} />
          <Route path='*' element={<Error type='404'>la page n'existe pas</Error>} />
        </Route>

      </Routes>
      </BrowserRouter>
    </div>

    );
}

export default App;
