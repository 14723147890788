import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='bg-primary '>
            <div className='text-white text-center'>
                <h3>Soleil du Loir</h3>
                <p>Association loi 1901</p>
            </div>
        </footer>
    );
};

export default Footer;